import React, { useEffect, useState, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import Map, {Marker}  from 'react-map-gl';
import { 
  FaSteamSymbol, 
  FaGlobe,
  FaNetworkWired,
  FaMapMarkerAlt,
  FaShieldAlt,
  FaClock
 } from 'react-icons/fa';
import "./App.css";


function Module ({ module, icon, title, sec }) {
  const IconComponent = icon;

  if (module === null) {
    module = <div className='m-loading'>Loading...</div>
  }

  return (
    <div className="module">
      <div className="m-bar">
      <IconComponent className="m-icon" />
        <div className="m-title">{title}</div>
        {sec ? <div className="m-sec">{sec}</div> : null}
      </div>
      {module}
    </div>
  );
};


function ModuleSteam ({ data }) {

  return (
    Module({ module: 
      (data && Object.keys(data).length > 0 && data.Type === "Games") ?
        Object.keys(data.Payload).map(gameName => (
            <div className="m-game-rc" key={gameName}>
              <img
                src={data.Payload[gameName].header_image_url}
                alt={gameName}
                className="m-game-hd-img"
              />
              <div className="m-game-info">
                <div className="m-game-title">{gameName}</div>
                <div className="m-game-time">{data.Payload[gameName].ingame ? 'Currently playing' : 'Played ' + data.Payload[gameName].last_played + ' ago'}</div>
              </div>
            </div>
        ))
      :
      null
      ,
        icon: FaSteamSymbol, 
        title: 'Steam' 
      })
  );
};



function ModuleLocation ({ data }) {
  const [dataState, setDataState] = useState(false);
  const [dataPayload, setDataPayload] = useState({});
  const [coords, setCoords] = useState({});
  const mapRef = useRef(null);

  if (!dataState && data && Object.keys(data).length > 0 && data.Type === "Location") {
    setDataState(true);
    setDataPayload(data.Payload);
    setCoords(data.Payload["loc"].split(","));
  }

  function handleLoad() {
    if (coords[0]) {
      mapRef.current?.flyTo({ center: [coords[1], coords[0]], duration: 5000, zoom: 10 });
    }
  }

  const getPrivacyTool = () => {
    const { privacy } = dataPayload;
    return privacy.tor ? 'Tor' :
           privacy.proxy ? 'Proxy' :
           privacy.vpn ? `VPN (${privacy.service})` :
           privacy.relay ? 'Relay' :
           privacy.hosting ? 'Hosting' :
           'None';
  };

  return (
    Module({ module:
      dataState ?
      <div className='m-location'>
        <div className='m-location-map'>
          <Map
            initialViewState={{
              longitude: coords[1],
              latitude: coords[0],
              zoom: 1
            }}
            ref={mapRef}
            // No reason to hide the token
            mapboxAccessToken="pk.eyJ1IjoiZm9sbC0iLCJhIjoiY20weTg2bDl0MDdmZjJrczhoYmc3YmQwYSJ9.2B1i8HYIWv6FNj98ZV5vtg"
            mapStyle="mapbox://styles/mapbox/dark-v11"
            onLoad={handleLoad}
          >
            <Marker longitude={coords[1]} latitude={coords[0] } color="red" />
          </Map>
        </div>

        <div className='m-location-info'>
          <div>
            <FaNetworkWired className="m-icon" />
            <strong>IP:</strong> {dataPayload.ip}
          </div>
          <div>
            <FaMapMarkerAlt className="m-icon" />
            <strong>Loc:</strong> {dataPayload.city}, {dataPayload.region}, {dataPayload.country}
          </div>
          <div>
            <FaShieldAlt className="m-icon" />
            <strong>Tool:</strong> {getPrivacyTool()}
          </div>
          <div>
            <FaClock className="m-icon" />
            <strong>TZ:</strong> {dataPayload.timezone}
          </div>
        </div>

      </div>
      :
      null
      ,
      icon: FaGlobe,
      title: 'Location'
    })
  );
}


function App() {

  const [data, setData] = useState({});
  const [wsIP, setWsIP] = useState('');
  const [hasUpdated, setHasUpdated] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // setWsIP('wss://ws.prondrix.com');
      if (!hasUpdated) {
        setData({"Type": "Location", "Payload": {"ip": "64.44.118.90", "city": "Buffalo", "region": "New York", "country": "US", "loc": "42.8865,-78.8784", "org": "AS131199 Nexeon Technologies, Inc.", "postal": "14202", "timezone": "America/New_York", "asn": {"asn": "AS131199", "name": "Nexeon Technologies, Inc.", "domain": "nexeon.com", "route": "64.44.118.0/24", "type": "hosting"}, "company": {"name": "PacketHub S.A.", "domain": "packethub.net", "type": "hosting"}, "privacy": {"vpn": true, "proxy": false, "tor": false, "relay": false, "hosting": true, "service": "NordVPN"}, "abuse": {"address": "PA, , Panama, Office 76, Plaza 2000, 50th Street and Marbella, Bella Vista, 0801", "country": "PA", "email": "abuse@packethub.tech", "name": "Abuse handle", "network": "64.44.118.0/24", "phone": "+5078336503"}, "domains": {"page": 0, "total": 0, "domains": []}, "isLimited": false}});
        setHasUpdated(true);
      } else {
        setData({"Type": "Games", "Payload": {"ELDEN RING": {"header_image_url": "https://steamcdn-a.akamaihd.net/steam/apps/1245620/library_hero.jpg", "last_played": "1 hour", "ingame": false, "game_id": 1245620}, "Stormworks: Build and Rescue": {"header_image_url": "https://steamcdn-a.akamaihd.net/steam/apps/573090/library_hero.jpg", "last_played": "20 hours", "ingame": false, "game_id": 573090}, "Unturned": {"header_image_url": "https://steamcdn-a.akamaihd.net/steam/apps/304930/library_hero.jpg", "last_played": "1 day", "ingame": false, "game_id": 304930}}});
      }
    } else {
      setWsIP('wss://ws.prondrix.com');
    }
  }, [hasUpdated]);

  const { sendJsonMessage } = useWebSocket(
    wsIP,
    {
      onOpen: () => sendJsonMessage({"Request": "Games"}),
      onMessage: (event) => setData(JSON.parse(event.data)),
      /*onClose: () => console.log('closed'),*/
    }
  );

  return (
    <>
      <img
          src="/background.png"
          className="img"
          alt="background"
        />
      <div className="div">
        <div className="main">
          <div className="general">

            <img className="pfp" src="/pfp.jpg" alt="pfp"></img>
            
              <div className="description">
                <div className="d-stuff">
                  <div>
                    <span>Name: Foll</span>
                  </div>
                  <div>
                    <span>Gender: ―――</span>
                  </div>
                  <div>
                    <span>Age: ――</span>
                  </div>
                </div>
                <div className="socials">
                  <div className="social-txt">Socials:</div>

                  <a className="social-btn" 
                  href="https://discord.com/users/435016127182274581" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    Discord
                  </a>

                  <a className="social-btn" 
                  href="https://steamcommunity.com/id/Foll2" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    Steam
                  </a>

                  <a className="social-btn" 
                  href="https://github.com/Foll2" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    Github
                  </a>

                </div>
              </div>
              
          </div>
          <div className="modules">
            <ModuleSteam data={data} />
            <div className='module-container'>
              <ModuleLocation data={data} />
              <div className='module-hidden'/>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default App;